import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import React from 'react';
import styled from 'styled-components';

export const ProgressBar = ({
  progress,
}: {
  progress: number;
}): JSX.Element => {
  return (
    <Scale>
      <ScaleStatus style={{ width: `${progress}%` }} />
    </Scale>
  );
};

const Scale = styled.div`
  height: 16px;
  border-radius: 1px;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.secondary};
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;

  @media screen and (max-width: ${Breakpoints.small}) {
    height: 10px;
    margin-bottom: 8px;
  }
`;

const ScaleStatus = styled.span`
  display: block;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.secondaryDim};
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (max-width: ${Breakpoints.small}) {
    height: 10px;
  }
`;
