import { HelpText } from 'components/HelpText';
import {
  selectCurrentPageIndex,
  selectHasValidated,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { validateQuestion } from 'features/survey/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from './DropdownItem';
import { QuestionItem, QuestionText } from './QuestionElements';
import { Select } from './Select';
import { ValidationMessage } from './ValidationMessage';

export const Dropdown = ({ question }: { question: Question }): JSX.Element => {
  const dispatch = useAppDispatch();
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const hasValidated = useAppSelector(selectHasValidated);
  const { t } = useTranslation();
  const validation = validateQuestion(question);
  const hasError = hasValidated && validation !== null;
  const key = `${question.id}_select`;

  const options = question.questionType.values?.map((option) => (
    <DropdownItem
      key={`${question.id}_${option.value}`}
      option={option}
      question={question}
    />
  ));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { number: +event.target.value },
      })
    );
  };

  return (
    question && (
      <QuestionItem key={question.id} className={hasError ? 'invalid' : ''}>
        <QuestionText>{t(`${question.displayNameKey}`)}</QuestionText>

        <Select
          id={key}
          name={key}
          onChange={handleChange}
          value={question.questionType.answer?.number?.toString()}
        >
          <option>{t('Survey.DropdownPlaceholder')}</option>
          {options}
        </Select>
        <HelpText helpText={question.helpTextKey} uniqueId={question.id} />

        {hasError && <ValidationMessage validation={validation} />}
      </QuestionItem>
    )
  );
};

Dropdown.displayName = 'Dropdown';
