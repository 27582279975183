import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { Trans, useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const HelpText = ({
  helpText,
  uniqueId,
}: {
  helpText?: string;
  uniqueId: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const formattedHelpText = !!helpText && t(helpText).trim();

  const helpElement = !!formattedHelpText && (
    <>
      <ReactTooltip
        id={`qHelp_${uniqueId}`}
        type="info"
        effect="solid"
        multiline={true}
        className="tooltip"
      >
        <Trans i18nKey={helpText} />
      </ReactTooltip>

      <HelpTextWrapper data-tip data-for={`qHelp_${uniqueId}`}>
        ?
      </HelpTextWrapper>
    </>
  );

  return <div>{helpElement}</div>;
};

HelpText.displayName = 'HelpText';

const HelpTextWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${({ theme }: { theme: Theme }) => theme.colors.secondary};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSecondary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  @media screen and (max-width: ${Breakpoints.small}) {
    top: 8px;
    right: 8px;
  }
`;
