import { Question, QuestionValue } from 'models';
import { useTranslation } from 'react-i18next';

export const DropdownItem = ({
  option,
  question,
}: {
  option: QuestionValue;
  question: Question;
}): JSX.Element => {
  const { t } = useTranslation();
  const key = `${question.id}_${option.value}`;

  return (
    <option key={key} id={key} value={option.value}>
      {t(`${option.optionKey}`)}
    </option>
  );
};

DropdownItem.displayName = 'DropdownItem';
