import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading2Center } from './Heading';
import { InformationMessage } from './InformationMessage';

export const Info = ({
  titleId,
  textId,
}: {
  titleId: string;
  textId: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);

  return (
    <InformationMessage id="informationMessage" ref={divRef} tabIndex={0}>
      <Heading2Center>{t(titleId)}</Heading2Center>
      {t(textId)}
    </InformationMessage>
  );
};

Info.displayName = 'Info';
