import { base, Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Survey Light',
  type: ThemeType.Light,

  colors: {
    ...base.colors,

    border: Color.Grey60,
  },

  fonts: {
    base: "normal 400 1.5rem/21px 'Roboto', sans-serif",
    title: "normal 300 2.1rem/32px 'Roboto', sans-serif",
    h1: "normal 400 3.5rem/39px 'Source Sans Pro', sans-serif",
    h2: "normal 400 2.5rem/28px 'Source Sans Pro', sans-serif",
    h3: "normal 400 1.8rem/24px 'Source Sans Pro', sans-serif",
    h4: "normal 400 1.6rem/24px 'Source Sans Pro', sans-serif",

    size: {
      base: '1.5rem',
      s: '1.4rem',
      xs: '1.3rem',
      xxs: '1.2rem',
      l: '1.6rem',
      xl: '1.8rem',
      xxl: '2.0rem',
    },
  },
};
