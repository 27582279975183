import { Theme } from '@dimatech/shared/lib/themes';
import {
  selectCurrentPageIndex,
  selectHasValidated,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { validateQuestion } from 'features/survey/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question, QuestionDisplaySubType } from 'models';
import React, { ChangeEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsCalendar } from 'react-icons/bs';
import styled from 'styled-components';
import {
  QuestionEntryField,
  QuestionItem,
  QuestionText,
} from './QuestionElements';
import { ValidationMessage } from './ValidationMessage';

export const DateElements = ({
  question,
}: {
  question: Question;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const hasValidated = useAppSelector(selectHasValidated);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const validation = validateQuestion(question);
  const hasError = hasValidated && validation !== null;

  // const [startDate, setStartDate] = useState<Date | null>(
  //   question.questionType.answer?.number
  //     ? new Date(question.questionType.answer?.number, 1)
  //     : null
  // );

  const isYearFuture =
    question.questionType.subType === QuestionDisplaySubType.YearFuture;
  const isYearPast =
    question.questionType.subType === QuestionDisplaySubType.YearPast;

  const handleChange = (event: Date) => {
    setIsOpen(!isOpen);
    // setStartDate(event);

    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { number: event.getFullYear() },
      })
    );
  };

  const handleClick = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleManualYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.currentTarget.value);

    if (isNaN(number)) {
      return false;
    }

    // setStartDate(new Date(number, 1));

    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { number },
      })
    );
  };

  const prevYear = new Date(new Date().getFullYear() - 1, 1);

  return (
    question && (
      <QuestionItem key={question.id} className={hasError ? 'invalid' : ''}>
        <QuestionText>{t(`${question.displayNameKey}`)}</QuestionText>

        <CalendarContainer>
          {/* TODO: This doesnt update when selecting year in datepicker, check */}
          <EntryField
            name={question.id.toString()}
            onChange={handleManualYearChange}
            value={question.questionType.answer?.number || ''}
            type="number"
          />

          <ButtonIcon onClick={handleClick}>
            <BsCalendar />
          </ButtonIcon>
        </CalendarContainer>

        {isOpen && (
          <DatePicker
            selected={
              question.questionType.answer?.number
                ? new Date(question.questionType.answer?.number, 1)
                : null
            }
            onChange={handleChange}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={9}
            inline
            minDate={isYearFuture ? prevYear : null}
            maxDate={isYearPast ? prevYear : null}
          />
        )}

        {hasError && <ValidationMessage validation={validation} />}
      </QuestionItem>
    )
  );
};

DateElements.displayName = 'DateElements';

const EntryField = styled(QuestionEntryField)`
  margin-top: 0;
  width: 170px;
  border-radius: 5px 0 0 5px;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.surface};

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      ${({ theme }: { theme: Theme }) => theme.colors.surface} inset !important;
    box-shadow: 0 0 0 30px
      ${({ theme }: { theme: Theme }) => theme.colors.surface} inset !important;
  }
`;

const ButtonIcon = styled.button`
  width: 30px;
  height: 34px;
  line-height: 34px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  border-left: none;
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};
  font-size: 15px;

  &:focus-visible {
    outline: none;
    color: ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;

const CalendarContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;
