import { ErrorMessage } from 'components/ErrorMessage';
import { Heading2Center } from 'components/Heading';
import { InformationMessage } from 'components/InformationMessage';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ServiceError = ({
  error,
  component,
}: {
  error: string;
  component: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <InformationMessage>
      <Heading2Center>{t(`Error.${component}.${error}.Title`)}</Heading2Center>
      <ErrorMessage>
        {t(`Error.${component}.${error}.Description`)}
      </ErrorMessage>
    </InformationMessage>
  );
};

ServiceError.displayName = 'ServiceError';
