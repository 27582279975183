import { ApplicationContext } from '@dimatech/shared/lib/application';
import { toTitleCase } from '@dimatech/shared/lib/utils';
import { ErrorMessage } from 'components/ErrorMessage';
import { Heading2Center } from 'components/Heading';
import { InformationMessage } from 'components/InformationMessage';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const IncorrectUrl = (): JSX.Element | null => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);

  useEffect(() => {
    document.querySelector('.loader-container')?.remove();

    if (application) {
      const applicationTitle = t(
        `Account.Name.${toTitleCase(application.subdomain)}`
      );

      document.title = `${applicationTitle} - ${t(
        'Error.Survey.IncorrectUrl.Title'
      )}`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  return (
    <>
      <InformationMessage>
        <Heading2Center>{t(`Error.Survey.IncorrectUrl.Title`)}</Heading2Center>
        <ErrorMessage>
          {t(`Error.Survey.IncorrectUrl.Description`)}
        </ErrorMessage>
      </InformationMessage>
    </>
  );
};

IncorrectUrl.displayName = 'IncorrectUrl';
