import { Breakpoints } from '@dimatech/shared/lib/themes';
import { HelpText } from 'components/HelpText';
import { selectHasValidated } from 'features/survey/api/surveySlice';
import { validateQuestion } from 'features/survey/validation';
import { useAppSelector } from 'hooks';
import { Question } from 'models';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Checkbox } from './Checkbox';
import { QuestionItem, QuestionText } from './QuestionElements';
import { Radio } from './Radio';
import { ValidationMessage } from './ValidationMessage';

export const Options = ({ question }: { question: Question }): JSX.Element => {
  const hasValidated = useAppSelector(selectHasValidated);
  const { t, i18n } = useTranslation();
  const validation = validateQuestion(question);
  const hasError = hasValidated && validation !== null;

  const classNamesRankingQuestions: string[] = [];
  if (hasError) classNamesRankingQuestions.push('invalid');
  if (question.isVertical || question.isCheckbox)
    classNamesRankingQuestions.push('isVertical');

  const classNamesWrapOptionsNoDescription: string[] = [];
  if (question.isVertical || question.isCheckbox)
    classNamesWrapOptionsNoDescription.push('isVertical');

  const options = question.questionType.values?.map((option) => (
    <Fragment key={`${question.id}_${option.value}`}>
      {question.isCheckbox ? (
        <Checkbox option={option} question={question} />
      ) : (
        <Radio option={option} question={question} />
      )}
    </Fragment>
  ));

  const hasDescription = !!question.questionType.values?.some(
    (option) => option.descriptionKey && i18n.exists(option.descriptionKey)
  );

  return (
    question && (
      <RankingQuestions
        key={question.id}
        className={classNamesRankingQuestions.join(' ')}
      >
        <QuestionText>{t(question.displayNameKey)}</QuestionText>
        <HelpText helpText={question.helpTextKey} uniqueId={question.id} />

        {!hasDescription ? (
          <WrapOptionsNoDescription
            className={classNamesWrapOptionsNoDescription.join(' ')}
          >
            {options}
          </WrapOptionsNoDescription>
        ) : (
          <WrapOptions>{options}</WrapOptions>
        )}

        {hasError && <ValidationMessage validation={validation} />}
      </RankingQuestions>
    )
  );
};

Options.displayName = 'Options';

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoints.small}) {
    flex-direction: column;
    margin-top: 32px;
  }
`;

const WrapOptionsNoDescription = styled(OptionsWrapper)`
  width: 50%;
  margin: 40px auto 0;
  padding-right: 16px;

  &.isVertical {
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
    padding-right: 0;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    width: 100%;
    padding-right: 0;

    &.isVertical {
      margin-top: 40px;
    }
  }
`;

const WrapOptions = styled(OptionsWrapper)`
  padding-top: 48px;
  position: relative;
  margin-left: 24px;
  margin-top: 40px;

  @media screen and (max-width: ${Breakpoints.small}) {
    padding-top: 0;
    margin-left: 0;
  }
`;

const RankingQuestions = styled(QuestionItem)`
  padding: 24px 16px 40px 32px;

  &.isVertical {
    padding-bottom: 16px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding: 36px 16px 16px;
  }
`;
