import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import {
  selectCurrentPageIndex,
  selectHasValidated,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { validateQuestion } from 'features/survey/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question, SurveyValidationError } from 'models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { QuestionText } from './QuestionElements';

export const FormattedText = ({
  question,
}: {
  question: Question;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const answer = question.questionType.answer;
  const hasValidated = useAppSelector(selectHasValidated);
  const validation = validateQuestion(question);
  const hasError = hasValidated && validation !== null;

  const handleSetValue = (value?: string) => {
    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { string: value },
      })
    );
  };

  return (
    <RootStyle key={question.id} className={hasError ? 'invalid' : ''}>
      <QuestionText>{t(`${question.displayNameKey}`)}</QuestionText>

      <TextEditor
        value={answer?.string || ''}
        setValue={(val) => {
          handleSetValue(val);
        }}
        messageId={question.id.toString()}
        allowLinks={false}
        validateHtmlIsEmpty={true}
      />

      <CharacterCounter>
        {validation?.includes(SurveyValidationError.MaxCharacters) && (
          <span>{t('TextEditor.ValidationError.LimitExceeded')}</span>
        )}
      </CharacterCounter>
    </RootStyle>
  );
};

FormattedText.displayName = 'FormattedText';

const RootStyle = styled.div`
  padding: 24px 32px;
  margin-bottom: 30px;

  color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background};
  border-bottom: 3px solid transparent;

  &.invalid {
    border-bottom: 3px solid
      ${({ theme }: { theme: Theme }) => theme.colors.error};
  }
  > div {
    margin-top: 10px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding: 36px 16px 0;
  }
`;

const CharacterCounter = styled.div`
  padding-top: 8px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.error};

  @media screen and (max-width: ${Breakpoints.small}) {
    padding-bottom: 16px;
  }
`;
