import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Button = styled.button`
  padding: 7px 13px;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
  text-align: center;
  border-radius: 3px;
  background: ${({ theme }: { theme: Theme }) => theme.colors.background};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
  margin-left: 32px;
  cursor: pointer;
  white-space: nowrap;

  &::focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0px 4px 4px
      ${({ theme }: { theme: Theme }) => theme.colors.shadow};
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-left: 16px;
    padding: 5px 8px;
  }
`;

export const ButtonPrimary = styled(Button)`
  &.active {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.secondaryVariant};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSecondary};
    border-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.secondaryVariant};
  }
`;

export const ButtonSecondary = styled(Button)`
  &.active {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.secondary};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSecondary};
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.secondary};
  }
`;

export const ButtonTertiary = styled(Button)`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.surface};
  color: ${({ theme }: { theme: Theme }) => theme.colors.secondary};
  border-color: ${({ theme }: { theme: Theme }) => theme.colors.secondary};
  opacity: 0.28;

  &.active {
    opacity: 1;
  }
`;

export const ButtonQuaternary = styled(Button)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.primaryVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
`;
