import { Breakpoints } from '@dimatech/shared/lib/themes';
import BackgroundInputChecked from 'assets/images/radio-bg-marked.png';
import BackgroundInput from 'assets/images/radio-bg.png';
import {
  selectCurrentPageIndex,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { Input, Label } from 'features/survey/question/OptionElements';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question, QuestionValue } from 'models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Radio = ({
  option,
  question,
}: {
  option: QuestionValue;
  question: Question;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const { t, i18n } = useTranslation();
  const key = `${question.id}_${option.value}`;
  const answer = question.questionType.answer;

  const hasDescription =
    !!option.descriptionKey && i18n.exists(option.descriptionKey);

  const handleChange = () => {
    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { number: +option.value },
      })
    );
  };

  return (
    <OptionsItem key={key} className={question.isVertical ? 'isVertical' : ''}>
      {hasDescription && (
        <OptionDescriptionText>
          {t(`${option.descriptionKey}`)}
        </OptionDescriptionText>
      )}

      <OptionInput
        type="radio"
        onChange={handleChange}
        key={key}
        id={key}
        name={question.id.toString()}
        value={option.value}
        defaultChecked={option.value === answer?.number?.toString()}
      />

      <OptionLabel
        htmlFor={key}
        className={question.isVertical ? 'isVertical' : ''}
      >
        {t(`${option.optionKey}`)}
      </OptionLabel>
    </OptionsItem>
  );
};

Radio.displayName = 'Radio';

const OptionsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.isVertical {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
  }
`;

const OptionDescriptionText = styled.div`
  position: absolute;
  top: -20px;
  width: 80px;
  text-align: center;

  @media screen and (max-width: ${Breakpoints.small}) {
    top: auto;
    left: 56px;
    width: 130px;
    text-align: left;
    line-height: 14px;
    margin-top: 4.2px;
  }
`;

const OptionInput = styled(Input)`
  &:checked + label {
    &:before {
      background: url(${BackgroundInputChecked}) 0 0 no-repeat;
    }
  }
`;

const OptionLabel = styled(Label)`
  flex-direction: column;

  &::before {
    background: url(${BackgroundInput}) 0 0 no-repeat;
    border-radius: 50%;
    margin: 0 auto 16px;
  }

  &.isVertical {
    flex-direction: row;
    align-items: center;

    &::before {
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    flex-direction: row;
  }
`;
