import { Theme } from '@dimatech/shared/lib/themes';
import {
  selectCurrentPageIndex,
  selectHasValidated,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { validateQuestion } from 'features/survey/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  QuestionEntryField,
  QuestionItem,
  QuestionText,
} from './QuestionElements';
import { ValidationMessage } from './ValidationMessage';

export const Number = ({ question }: { question: Question }): JSX.Element => {
  const dispatch = useAppDispatch();
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const hasValidated = useAppSelector(selectHasValidated);
  const { t } = useTranslation();
  const validation = validateQuestion(question);
  const hasError = hasValidated && validation !== null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value);

    if (isNaN(number)) {
      return false;
    }

    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { number },
      })
    );
  };

  return (
    question && (
      <QuestionItem key={question.id} className={hasError ? 'invalid' : ''}>
        <QuestionText>{t(`${question.displayNameKey}`)}</QuestionText>

        <EntryField
          name={question.id.toString()}
          defaultValue={question.questionType.answer?.number}
          onChange={handleChange}
          type="number"
        />

        {hasError && <ValidationMessage validation={validation} />}
      </QuestionItem>
    )
  );
};

Number.displayName = 'Number';

const EntryField = styled(QuestionEntryField)`
  width: 120px;
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
`;
