import { Breakpoints } from '@dimatech/shared/lib/themes';
import { Heading2 } from 'components/Heading';
import { useAppSelector } from 'hooks';
import { Question, QuestionDisplaySubType, QuestionDisplayType } from 'models';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  selectCanSelectEntity,
  selectCurrentPageIndex,
  selectHasValidated,
  selectNextInvalidQuestion,
  selectPage,
  selectQuestionCount,
} from './api/surveySlice';
import { DateElements } from './question/DateElements';
import { Dropdown } from './question/Dropdown';
import { EntityTree } from './question/EntityTree';
import { FormattedText } from './question/FormattedText';
import { Number } from './question/Number';
import { Options } from './question/Options';
import { PageHelp } from './question/PageHelp';

export const Page = (): JSX.Element | null => {
  const { t, i18n } = useTranslation();
  const page = useAppSelector(selectPage);
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const hasValidated = useAppSelector(selectHasValidated);
  const questionCount = useAppSelector(selectQuestionCount);
  const canSelectEntity = useAppSelector(selectCanSelectEntity);
  const nextInvalidQuestion = useAppSelector(selectNextInvalidQuestion);

  const itemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const lastAnswered = page?.questions.reduce(
      (
        questionId: number | null,
        question: Question,
        index: number,
        questions: Question[]
      ) => {
        const isAnswered =
          question.questionType.isMandatory && question.questionType.answer;

        if (isAnswered && index < questions.length - 1)
          return questions[index + 1].id;
        else return questionId || null;
      },
      null
    );

    if (lastAnswered) {
      itemsRef.current[lastAnswered]?.scrollIntoView();
    } else {
      document.querySelector('#root')?.scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    if (!hasValidated || !nextInvalidQuestion) {
      return;
    }

    itemsRef.current[nextInvalidQuestion]?.scrollIntoView();
  }, [hasValidated, nextInvalidQuestion]);

  const introText =
    page?.descriptionKey &&
    i18n.exists(page?.descriptionKey) &&
    t(page?.descriptionKey);

  return page ? (
    <div key={page.id}>
      {!!introText && page.isFirst && (
        <IntroBox>
          <Trans i18nKey={`${page?.descriptionKey}`} count={questionCount} />
        </IntroBox>
      )}

      {page.headerKey && i18n.exists(page.headerKey) && (
        <Heading2>{t(page.headerKey)}</Heading2>
      )}

      {(!page.isFirst || (page.isFirst && page.isLast)) && (
        <PageHelp>
          <Trans i18nKey={'Survey.Help'} />
        </PageHelp>
      )}

      {page.isFirst && canSelectEntity && <EntityTree />}

      {page.questions.map((question) => {
        return (
          <div
            key={question.id}
            ref={(element: HTMLDivElement) => {
              itemsRef.current[question.id] = element;
            }}
          >
            {question.questionType.type === QuestionDisplayType.Option &&
              (question.questionType.subType ===
              QuestionDisplaySubType.Radio ? (
                <Options question={question} />
              ) : (
                <Dropdown question={question} />
              ))}

            {question.questionType.type ===
              QuestionDisplayType.FormattedText && (
              <FormattedText question={question} />
            )}

            {question.questionType.type === QuestionDisplayType.Number && (
              <Number question={question} />
            )}

            {question.questionType.type === QuestionDisplayType.Date && (
              <DateElements question={question} />
            )}
          </div>
        );
      })}
    </div>
  ) : null;
};

Page.displayName = 'Page';

const IntroBox = styled.div`
  margin-bottom: 30px;

  > p {
    padding-top: 24px;
    width: 85%;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-bottom: 40px;

    > p {
      width: 100%;
      padding: 16px 24px 0;
    }
  }
`;
