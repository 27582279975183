import { useEffect, useState } from 'react';
export var useTimer = function (interval) {
    var _a = useState(0), elapsed = _a[0], setElapsed = _a[1];
    var _b = useState(false), isActive = _b[0], setIsActive = _b[1];
    var start = function () {
        setIsActive(true);
    };
    var stop = function () {
        setIsActive(false);
    };
    var reset = function () {
        setIsActive(true);
        setElapsed(0);
    };
    useEffect(function () {
        var intervalId = null;
        if (isActive) {
            intervalId = setInterval(function () {
                setElapsed(elapsed + 1);
            }, interval);
        }
        return function () {
            if (intervalId)
                clearInterval(intervalId);
        };
    }, [isActive, elapsed, interval]);
    return [elapsed, isActive, reset, start, stop];
};
