import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonQuaternary } from './Button';

export const Modal = ({
  children,
  visible,
  setVisible,
  id,
}: {
  children: string | JSX.Element;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (visible && modalRef.current) {
      modalRef.current.focus();
    }
  }, [visible]);

  return ReactDOM.createPortal(
    <Background
      className={visible ? 'modal active' : 'modal'}
      id={id}
      aria-modal="true"
      ref={modalRef}
      tabIndex={-1}
    >
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        <ButtonQuaternary onClick={() => setVisible(false)} id={`button${id}`}>
          {t('Survey.Message.Button')}
        </ButtonQuaternary>
      </ModalFooter>
    </Background>,

    document.getElementById('portal') as HTMLElement
  );
};

const Background = styled.div`
  display: none;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
  border: 0.2px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  box-shadow: 0px 4px 4px
    ${({ theme }: { theme: Theme }) => theme.colors.shadow};
  border-radius: 5px;
  position: fixed;
  top: 250px;
  right: 50%;
  transform: translatex(50%);
  z-index: 111;
  &.active {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: ${Breakpoints.small}) {
    top: 135px;
    max-width: 360px;
  }
`;

const ModalContent = styled.div`
  padding: 24px 24px 8px;
`;

const ModalFooter = styled.div`
  display: flex;
  // justify-content: end;
  justify-content: flex-end;
  padding: 0 24px 24px;
`;
