import { Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Select = styled.select`
  width: 240px;
  height: 34px;
  border-radius: 5px;

  margin-top: 24px;
  padding: 6px 8px;

  &:focus-visible {
    outline: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;

Select.displayName = 'Select';
