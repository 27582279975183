import { Breakpoints } from '@dimatech/shared/lib/themes';
import { Header } from 'components/Header';
import { Survey as SurveyFeature } from 'features/survey';
import styled from 'styled-components';

export const Survey = (): JSX.Element => {
  return (
    <>
      <Header />
      <SurveyCanvas>
        <SurveyFeature />
      </SurveyCanvas>
    </>
  );
};

const SurveyCanvas = styled.div`
  width: 90%;
  max-width: 858px;
  margin: 0 auto;
  margin-top: 40px;

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-top: 0;
    width: 100%;
  }
`;
