import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { SurveyName } from '@dimatech/shared/lib/models';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { Redirect } from 'components/Redirect';
import { config } from 'config';
import { IncorrectUrl } from 'features/survey/IncorrectUrl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Maintenance } from 'views/Maintenance';
import { Survey } from 'views/Survey';

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMaintenanceOn =
    useFlags()[flags.permanent.app.survey.isMaintenanceOn];

  useEffect(() => {
    if (isMaintenanceOn === true) {
      navigate('/maintenance', {
        state: { from: location.pathname },
      });
    } else if (
      isMaintenanceOn !== true &&
      location.pathname === '/maintenance'
    ) {
      navigate((location.state as { from: string })?.from ?? '/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaintenanceOn]);

  config.currentSurvey = location.pathname.startsWith('/dikios/')
    ? SurveyName.Dikios
    : SurveyName.Dimios;

  return (
    <ThemeProvider themes={themes}>
      <ApplicationProvider>
        <GlobalStyles />
        <Routes>
          <Route path="/survey/go/:token" element={<Survey />} />
          <Route
            path="/survey/preview/:surveyId/:customerId"
            element={<Survey />}
          />
          <Route
            path="/survey/preview/:surveyId/:customerId/:entityId"
            element={<Survey />}
          />

          <Route path="/dikios/go/:token" element={<Survey />} />
          <Route path="/go/:token" element={<Survey />} />

          <Route
            path="/dikios/preview/:surveyId/:customerId"
            element={<Survey />}
          />
          <Route
            path="/dikios/preview/:surveyId/:customerId/:entityId"
            element={<Survey />}
          />
          <Route path="/preview/:surveyId/:customerId" element={<Survey />} />
          <Route
            path="/preview/:surveyId/:customerId/:entityId"
            element={<Survey />}
          />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/incorrecturl" element={<IncorrectUrl />} />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </ApplicationProvider>
    </ThemeProvider>
  );
};

export default App;
