import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Header } from 'components/Header';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Maintenance = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Style>
        <Heading2 style={{ marginTop: 20 }}>{t('Maintenance.Title')}</Heading2>
        <div style={{ marginTop: 20 }}>{t('Maintenance.Text')}</div>
      </Style>
    </>
  );
};

Maintenance.displayName = 'Maintenance';

const Style = styled.div`
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 40px;
`;
