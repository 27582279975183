import { useTimer } from '@dimatech/shared/lib/hooks';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { Modal } from 'components/Modal';
import { ProgressBar } from 'components/ProgressBar';
import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  selectMandatoryAnsweredCount,
  selectPage,
  selectProgressPercent,
  selectQuestionCount,
  selectSurvey,
} from './api/surveySlice';
import { FooterButtons } from './FooterButtons';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  const [elapsed, isSaveTimerActive, resetSaveTimer] = useTimer(60000);
  const [msgAnyProblem, setMsgAnyProblem] = useState(false);
  const [msgNotAllAnswers, setMsgNotAllAnswers] = useState(false);
  const survey = useAppSelector(selectSurvey);
  const questionsAnswered = useAppSelector(selectMandatoryAnsweredCount);
  const questionCount = useAppSelector(selectQuestionCount);
  const percent = useAppSelector(selectProgressPercent);
  const page = useAppSelector(selectPage);

  return (
    <FooterContainer>
      <Modal
        id="AnyProblem'"
        visible={msgAnyProblem}
        setVisible={setMsgAnyProblem}
      >
        {t('Survey.Message.AnyProblem')}
      </Modal>

      <Modal
        id="NotAllAnswers"
        visible={msgNotAllAnswers}
        setVisible={setMsgNotAllAnswers}
      >
        {page?.isLast
          ? t('Survey.Message.NotAllAnswersLastPage')
          : t('Survey.Message.NotAllAnswers')}
      </Modal>

      <ProgressBar progress={percent} />

      <div className="survey-footer-container">
        <div className="survey-footer-status">
          <NumberOfResponses>
            {t('Survey.NumberOfResponses', {
              questionsAnswered,
              questionCount,
            })}
          </NumberOfResponses>

          {elapsed > 0 && (
            <SaveTime>
              {t('Survey.SavedXMinutesAgo', { count: elapsed })}
            </SaveTime>
          )}

          {survey?.updated && !isSaveTimerActive && (
            <SaveTime>
              {t('Survey.SavedLastTimeAt', { date: new Date(survey.updated) })}
            </SaveTime>
          )}

          {isSaveTimerActive && elapsed === 0 && (
            <SaveTime>{t('Survey.SavedJustNow')}</SaveTime>
          )}
        </div>

        <FooterButtons
          resetSaveTimer={resetSaveTimer}
          setMsgAnyProblem={setMsgAnyProblem}
          setMsgNotAllAnswers={setMsgNotAllAnswers}
        />
      </div>
    </FooterContainer>
  );
};

Footer.displayName = 'Footer';

const FooterContainer = styled.div`
  padding: 24px 64px;
  filter: drop-shadow(
    3px -3px 3px ${({ theme }: { theme: Theme }) => theme.colors.shadow}
  );
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 954px;
  z-index: 999;

  .survey-footer-container {
    display: flex;
    flex-wrap: wrap;
  }

  .survey-footer-status {
    width: 50%;
  }

  .survey-footer-buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media screen and (max-width: ${Breakpoints.medium}) {
    max-width: 100%;
    width: 100%;

    .survey-footer-status {
      width: 100%;
    }

    .survey-footer-buttons {
      width: 100%;
    }
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding: 16px;

    .survey-footer-buttons {
      margin-top: 16px;
    }
  }
`;

const NumberOfResponses = styled.div`
  margin-bottom: 16px;
  font-weight: bold;

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-bottom: 8px;
  }
`;

const SaveTime = styled.div`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};
  font-style: italic;
`;
