import { Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const PageHelp = styled.div`
  margin-bottom: 32px;
  padding: 20px;
  background: ${({ theme }: { theme: Theme }) => theme.colors.background};
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  font-style: italic;
`;
