import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Heading1 = styled.h1`
  padding-bottom: 24px;
  border-bottom: 1px solid
    ${({ theme }: { theme: Theme }) => theme.colors.border};
  font: ${({ theme }: { theme: Theme }) => theme.fonts.h1};

  @media screen and (max-width: ${Breakpoints.small}) {
    padding-bottom: 8px;
    margin: 0 24px;
  }
`;

export const Heading2 = styled.h2`
  margin-bottom: 24px;
  font: ${({ theme }: { theme: Theme }) => theme.fonts.h2};

  @media screen and (max-width: ${Breakpoints.small}) {
    margin: 0 24px 8px;
  }
`;

export const Heading2Center = styled(Heading2)`
  text-align: center;
  margin-bottom: 8px;
`;
