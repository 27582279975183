/* 
We allow this here since this is the entry point of 
getting all settings from the .env files
*/
/* eslint-disable no-process-env */
import {
  config as sharedConfig,
  Config as SharedConfig,
} from '@dimatech/shared/lib/config';
import { SurveyName } from '@dimatech/shared/lib/models';

// NOTE: Add settings here that are unique for this app, shared settings should be added to shared config

export const config: Config = {
  ...sharedConfig,
  fallbackUrl: process.env.REACT_APP_REDIRECT_URL,
  currentSurvey: null,
  environment: {
    ...sharedConfig.environment,
    testServerSurvey: process.env.REACT_APP_TEST_SERVER_SURVEY as Surveys,
  },
};

type Surveys = 'dios' | 'dimios';

type Config = SharedConfig & {
  fallbackUrl?: string;
  currentSurvey: SurveyName | null;
  environment: {
    testServerSurvey: Surveys;
  };
};
