import { Breakpoints } from '@dimatech/shared/lib/themes';
import { ErrorMessage } from 'components/ErrorMessage';
import { SurveyValidationError } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ValidationMessage = ({
  validation,
}: {
  validation: SurveyValidationError[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ErrorTextWrapper>
      {validation.includes(SurveyValidationError.Required) && (
        <ErrorMessage>{t('Survey.Error.Required')}</ErrorMessage>
      )}

      {validation.includes(SurveyValidationError.YearOnly) && (
        <ErrorMessage>{t('Survey.Error.YearOnly')}</ErrorMessage>
      )}

      {validation.includes(SurveyValidationError.DigitsOnly) && (
        <ErrorMessage>{t('Survey.Error.DigitsOnly')}</ErrorMessage>
      )}
    </ErrorTextWrapper>
  );
};

const ErrorTextWrapper = styled.div`
  position: absolute;
  left: 32px;
  bottom: 8px;

  @media screen and (max-width: ${Breakpoints.small}) {
    left: 16px;
  }
`;
