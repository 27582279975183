import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  text-align: center;
  &::before {
    height: 20px;
    width: 20px;
    content: ' ';
    display: inline-block;
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-bottom: 0;
    flex-direction: row;
    align-items: center;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + label {
    &:before {
      border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
    }
  }
  &:focus + label:before {
    outline: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    & + label {
      &:before {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
`;
