import axios from 'axios';
import {
  GenericServiceError,
  HttpStatus,
  NotFoundError,
  ServiceUnavailableError,
  UnhandledError,
} from 'models';

// This will intercept all requests and responses done using axios

export const initializeAxiosResponseInterceptor = (): void => {
  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      // All status codes except 2xx causes this function to trigger
      if (error.response === undefined) {
        return Promise.reject(new ServiceUnavailableError(error));
      } else if (error.response.status === HttpStatus.NotFound) {
        return Promise.reject(new NotFoundError(error));
      } else if (error.response.status === HttpStatus.InternalServerError) {
        return Promise.reject(new GenericServiceError(error));
      }

      return Promise.reject(new UnhandledError(error));
    }
  );
};
