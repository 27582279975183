import { combineReducers } from '@reduxjs/toolkit';
import { answerReducer } from 'features/survey/api/answerSlice';
import { surveyReducer } from 'features/survey/api/surveySlice';

export const rootReducer = combineReducers({
  survey: surveyReducer,
  answer: answerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
