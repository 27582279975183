import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { formatISO } from 'date-fns';
import { validatePage, validateQuestion } from 'features/survey/validation';
import {
  Answer,
  Page,
  Question,
  QuestionDisplaySubType,
  SurveyName,
  SurveyStatus,
  SurveyToken,
} from 'models';
import { RootState } from 'store';

type SurveyState = {
  currentPageIndex: number;
  questionCount: number;
  questionsAnswered: number[];
  invalidQuestions: (number | null)[];
  data: SurveyToken | null;
  isFetching: boolean;
  hasValidated: boolean;
  error?: string | null;
};

const initialState: SurveyState = {
  currentPageIndex: 0,
  questionCount: 0,
  questionsAnswered: [],
  invalidQuestions: [],
  data: null,
  isFetching: false,
  hasValidated: false,
  error: null,
};

export const getSurvey = createAsyncThunk(
  'survey/get',
  async ({
    token,
    surveyId,
    customerId,
    entityId,
  }: {
    token: string;
    surveyId: string;
    customerId: string;
    entityId?: string;
  }) => {
    const apiUrl =
      config.currentSurvey === SurveyName.Dikios
        ? config.apiUrl.survey.dikios
        : config.apiUrl.survey.dimios;

    const url = token
      ? `${apiUrl}survey/go/${token}`
      : `${apiUrl}survey/preview/${surveyId}${
          entityId ? `?customerId=${customerId}&entityId=${entityId}` : ''
        }`;

    // For testing dios survey
    //const url = 'http://localhost:3000/test-data/survey_dios.json';

    const { data } = await axios.get(url);

    return data;
  }
);

export const { reducer: surveyReducer, actions: surveyActions } = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    nextPage: (state) => {
      state.currentPageIndex += 1;
    },
    previousPage: (state) => {
      state.currentPageIndex -= 1;
    },
    answer: (
      state,
      action: PayloadAction<{
        questionId: number;
        pageIndex: number;
        answer: Answer;
      }>
    ) => {
      const page = state.data?.form.pages[action.payload.pageIndex];
      if (!page) {
        return;
      }

      const question = page.questions.find(
        ({ id }) => id === action.payload.questionId
      );

      if (!question) {
        return;
      }

      question.questionType.answer = {
        ...question.questionType.answer,
        ...action.payload.answer,
      };

      page.isValid = validatePage({ ...page });

      if (!question.questionType.isMandatory) {
        return;
      }

      // NOTE: We could probably replace 'questionsAnswered' with a selector instead
      const answered = new Set(state.questionsAnswered);

      if (validateQuestion(question) === null) {
        answered.add(question.id);
      } else {
        answered.delete(question.id);
      }

      state.questionsAnswered = Array.from(answered);
    },
    updateStatus: (state, action: PayloadAction<SurveyStatus>) => {
      if (state.data) {
        state.data.status = action.payload;
        state.data.updated = formatISO(new Date());
      }
    },
    updateEntityId: (state, action: PayloadAction<string | undefined>) => {
      if (state.data) {
        state.data.entityId = action.payload;
      }
    },
    updateHasValidated: (state, action: PayloadAction<boolean>) => {
      state.hasValidated = action.payload;
    },
    updateInvalidQuestions: (
      state,
      action: PayloadAction<(number | null)[]>
    ) => {
      state.invalidQuestions = action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getSurvey.fulfilled, (state, action) => {
        const payload = { ...action.payload };
        const { currentPageIndex, questionsAnswered, questionCount } =
          mapProgress(payload);

        state.currentPageIndex = currentPageIndex;
        state.questionCount = questionCount;
        state.questionsAnswered = questionsAnswered;
        state.data = mapSurvey(
          payload,
          payload.token,
          payload.surveyId ?? action.meta.arg.surveyId
        );
        state.isFetching = false;
      })
      .addCase(getSurvey.pending, (state, action) => {
        state.error = null;
        state.isFetching = true;
      })
      .addCase(getSurvey.rejected, (state, action) => {
        state.error = action.error.name;
        state.isFetching = false;
      });
  },
});

export const selectSurveyError = (
  state: RootState
): string | null | undefined => state.survey.error;

export const selectHasValidated = (state: RootState): boolean =>
  state.survey.hasValidated;

export const selectSurvey = (state: RootState): SurveyToken | null =>
  state.survey.data;

export const selectEntityId = (state: RootState): string | undefined =>
  state.survey.data?.entityId;

export const selectCanSelectEntity = (state: RootState): boolean | undefined =>
  state.survey?.data?.form?.entities &&
  state.survey.data.form.entities?.length > 0;

export const selectMandatoryAnsweredCount = (state: RootState): number =>
  state.survey.questionsAnswered.length +
  (state.survey.data?.form?.entities &&
  state.survey.data?.form.entities.length > 0 &&
  state.survey.data?.entityId
    ? 1
    : 0);

export const selectQuestionCount = (state: RootState): number =>
  state.survey.questionCount;

export const selectCurrentPageIndex = (state: RootState): number =>
  state.survey.currentPageIndex;

export const selectProgressPercent = (state: RootState): number =>
  100 -
  (state.survey.questionCount
    ? ((state.survey.questionsAnswered.length +
        (state.survey.data?.form?.entities &&
        state.survey.data?.form.entities.length > 0 &&
        state.survey.data?.entityId
          ? 1
          : 0)) /
        state.survey.questionCount) *
      100
    : 0);

export const selectPage = (state: RootState): Page | null =>
  state.survey?.data?.form?.pages
    ? state.survey.data.form.pages[state.survey.currentPageIndex]
    : null;

export const selectNextInvalidQuestion = (
  state: RootState
): number | undefined | null =>
  state.survey.invalidQuestions.find((question) => question != null);

// TODO: Clean up here, these mappers seems overly complicated

// Map progress
const mapProgress = (
  data: SurveyToken
): {
  currentPageIndex: number;
  questionsAnswered: number[];
  questionCount: number;
} => {
  const pages = data.form?.pages;

  if (!pages) {
    return {
      currentPageIndex: 0,
      questionCount: 0,
      questionsAnswered: [],
    };
  }

  const questions = pages
    .reduce((question: Question[], page) => question.concat(page.questions), [])
    .filter((question) => question.questionType.isMandatory);

  const questionsAnswered = questions
    .filter((question) => !!question.questionType.answer)
    .map((question) => question.id);

  let currentPageIndex = pages.findIndex((page) =>
    page.questions.findIndex(
      (question) => question.questionType.answer !== null
    )
  );

  const pageAnswered =
    currentPageIndex > -1
      ? pages[currentPageIndex].questions
          .filter((questions) => questions.questionType.isMandatory)
          .every(
            (question) =>
              question.questionType.isMandatory &&
              !!question.questionType.answer
          )
      : 0;

  if (pageAnswered && currentPageIndex < pages.length - 1) {
    currentPageIndex++;
  }

  return {
    currentPageIndex: currentPageIndex !== -1 ? currentPageIndex : 0,
    // Add 1 to question count if respondent should select which entity to answer for
    questionCount:
      questions.length +
      (data.form?.entities && data.form.entities.length > 0 ? 1 : 0),
    questionsAnswered,
  };
};

// Re-map and sort pages, questions and options
const mapSurvey = (
  response: SurveyToken,
  token?: string,
  previewSurveyId?: string
): SurveyToken => {
  const surveyId = response.surveyId ?? previewSurveyId;
  let questionIndex = 0;

  const pages = response.form?.pages?.map((page, index) => {
    page.isFirst = index === 0;
    page.isLast = index === response.form.pages.length - 1;
    page.isValid = validatePage(page);

    page.headerKey = `Survey.${surveyId}.Page.${page?.id}.Header`;
    page.descriptionKey = `Survey.${surveyId}.Page.${page?.id}.Description`;

    page.questions.map((question) => {
      question.index = questionIndex++;
      question.displayNameKey = `Survey.${surveyId}.Question.${question.id}.Label`;
      question.helpTextKey = `Survey.${surveyId}.Question.${question.id}.Help`;

      question.questionType.values?.map((value) => {
        value.optionKey = `Survey.${surveyId}.Option.${value.value}.Label`;
        value.descriptionKey = `Survey.${surveyId}.Option.${value.value}.Description`;

        return value;
      });

      question.isCheckbox =
        question.questionType.subType === QuestionDisplaySubType.Checkbox;

      question.isVertical =
        question.questionType.subType === QuestionDisplaySubType.VerticalOption;

      question.questionType.values = question.questionType.values?.sort(
        (option1, option2) => option1.sortOrder - option2.sortOrder
      );

      return question;
    });

    return page;
  });

  const survey: SurveyToken = {
    ...response,
    surveyId,
    form: {
      ...response.form,
      displayNameKey: `Survey.${surveyId}.Title`,
      descriptionKey: `Survey.${surveyId}.Description`,
      pages,
    },
    isReadOnly: !token,
    // If user should select entity in survey, reset this so that the user is forced to select entity
    entityId:
      response.form?.entities && response.form.entities.length > 0
        ? undefined
        : response.entityId,
  };

  return survey;
};
