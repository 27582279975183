import { config } from 'config';
import { NodeEnvironment } from 'models';
import React from 'react';

export const Redirect = (): JSX.Element => {
  // If we are running tests, do not redirect, just show redirect url instead so we can verify it.
  if (config.environment.node === NodeEnvironment.Test) {
    return <>{config.fallbackUrl}</>;
  }

  config.fallbackUrl && window.location.replace(config.fallbackUrl);

  return <br />;
};

Redirect.displayName = 'Redirect';
