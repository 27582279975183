import {
  SwitchLocale,
  SwitchTheme,
} from '@dimatech/shared/lib/components/workspace';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Header = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Background>
      <div>
        <Logo>{t(`Account.Name.${config.currentSurvey}`)}</Logo>
        <div>
          <SwitchTheme dark={dark.name} light={light.name} />
          <SwitchLocale />
        </div>
      </div>
      <Rectangle />
    </Background>
  );
};

Header.displayName = 'Header';

const Background = styled.div`
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};

  > div {
    width: 100%;
    min-height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

const Logo = styled.div`
  font: ${({ theme }: { theme: Theme }) => theme.fonts.title};
  height: 63px;
  margin-left: 32px;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.small}) {
    height: 44px;
    margin-left: 16px;
  }
`;

const Rectangle = styled.div`
  height: 32px;
  border-top: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.primary};
  border-bottom: 3px solid
    ${({ theme }: { theme: Theme }) => theme.colors.primary};

  @media screen and (max-width: ${Breakpoints.small}) {
    height: 14px;
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
`;
