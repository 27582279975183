import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { createGlobalStyle, css } from 'styled-components';
import { datepicker } from './datepicker';

// NOTE: This is a workaround for the issue that prettier doesnt support code formatting in createGlobalStyle
const base = css`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  li {
    margin: 0 20px;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font: ${({ theme }: { theme: Theme }) => theme.fonts.base};
    background: ${({ theme }: { theme: Theme }) => theme.colors.background};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};

    @media screen and (max-width: ${Breakpoints.small}) {
      background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
      color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};
    }
  }

  .tooltip {
    max-width: 40% !important;
    min-width: 300px !important;

    .multi-line {
      text-align: left !important;
    }

    @media screen and (max-width: ${Breakpoints.medium}) {
      max-width: 60% !important;
      min-width: 250px !important;
    }

    @media screen and (max-width: ${Breakpoints.small}) {
      max-width: 70% !important;
      min-width: 200px !important;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  // NOTE: Do not add styles here, add them to 'base'
  ${base}

  ${datepicker}
  `;
