import { Entity, SurveyStatus, SurveyType } from '@dimatech/shared/lib/models';

export type SurveyToken = {
  token: string;
  surveyId: string;
  type: SurveyType;
  locale: string;
  theme: string;
  status: SurveyStatus;
  validFrom: Date;
  validTo?: Date;
  instanceId?: number;
  entityId?: string;
  organizationId: number;
  organizationName: string;
  lastAnsweredPage?: number;
  created: string;
  updated?: string;
  form: Form;
  isReadOnly: boolean;
};

export type Form = {
  displayNameKey: string;
  descriptionKey: string;
  created: string;
  updated?: string;
  pages: Page[];
  entities?: Entity[];
};

export type Page = {
  id: number;
  headerKey?: string;
  descriptionKey?: string;
  questions: Question[];
  isFirst: boolean;
  isLast: boolean;
  isValid: boolean;
};

export type Question = {
  id: number;
  displayNameKey: string;
  helpTextKey: string;
  questionType: QuestionType;
  index?: number;
  isCheckbox: boolean;
  isVertical: boolean;
};

export type QuestionType = {
  isMandatory: boolean;
  type: QuestionDisplayType;
  subType?: QuestionDisplaySubType;
  values?: QuestionValue[];
  answer?: Answer;
};

export type QuestionValue = {
  value: string;
  optionKey: string;
  descriptionKey?: string;
  sortOrder: number;
};

export type Answer = {
  string?: string;
  number?: number;
  array?: number[];
};

export enum QuestionDisplayType {
  Option = 'Option',
  FormattedText = 'FormattedText',
  Number = 'Number',
  Date = 'Date',
}

export enum QuestionDisplaySubType {
  Radio = 'Radio',
  Dropdown = 'Dropdown',
  Checkbox = 'Checkbox',
  VerticalOption = 'VerticalOption',
  HorizontalOption = 'HorizontalOption',
  YearPast = 'YearPast',
  YearFuture = 'YearFuture',
}
