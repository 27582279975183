import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const ErrorTextWrapper = styled.div`
  position: absolute;
  left: 32px;
  bottom: 8px;

  @media screen and (max-width: ${Breakpoints.small}) {
    left: 16px;
  }
`;

export const QuestionText = styled.p`
  width: 85%;

  @media screen and (max-width: ${Breakpoints.small}) {
    width: 100%;
  }
`;

export const QuestionItem = styled.div`
  margin-bottom: 32px;
  padding: 24px 32px 40px;
  position: relative;
  border-bottom: 3px solid transparent;
  background: ${({ theme }: { theme: Theme }) => theme.colors.background};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-bottom: 24px;
    padding: 36px 16px 40px;
  }

  &.invalid {
    border-bottom: 3px solid
      ${({ theme }: { theme: Theme }) => theme.colors.error};
  }
`;

export const QuestionEntryField = styled.input`
  height: 34px;
  border-radius: 5px;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  outline: none;
  text-align: right;
  margin-top: 24px;
  padding: 6px 8px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      ${({ theme }: { theme: Theme }) => theme.colors.background} inset !important;
    box-shadow: 0 0 0 30px
      ${({ theme }: { theme: Theme }) => theme.colors.background} inset !important;
  }
`;
