import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const InformationMessage = styled.div`
  display: inline-block;
  padding: 24px;
  margin-top: 224px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
  box-shadow: 0px 4px 4px
    ${({ theme }: { theme: Theme }) => theme.colors.shadow};
  border-radius: 5px;

  @media screen and (max-width: ${Breakpoints.small}) {
    margin-top: 150px;
    min-width: 246px;
  }
`;
