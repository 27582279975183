import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { Question, SurveyName, SurveyStatus } from 'models';
import { RootState } from 'store';

const initialState: {
  status?: SurveyStatus;
  isSaving: boolean;
  error?: string | null;
} = {
  isSaving: false,
  error: null,
};

export const saveAnswers = createAsyncThunk(
  'answer/save',
  async ({
    questions,
    token,
    entityId,
  }: {
    questions: Question[];
    token: string;
    entityId?: string;
  }) => {
    const answers = questions.map((question) => {
      // NOTE: We should probably sync the backend with the front end so we dont need to do this mapping
      return {
        questionId: question.id,
        answer: {
          string: question.questionType.answer?.string,
          number:
            question.questionType.answer?.array ??
            question.questionType.answer?.number,
        },
      };
    });

    const apiUrl =
      config.currentSurvey === SurveyName.Dikios
        ? config.apiUrl.survey.dikios
        : config.apiUrl.survey.dimios;

    const url = `${apiUrl}survey/answer`;

    const { data } = await axios.post(url, { answers, token, entityId });

    return data?.status as SurveyStatus;
  }
);

export const { reducer: answerReducer, actions: answerActions } = createSlice({
  name: 'answer',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(saveAnswers.fulfilled, (state, action) => {
        state.status = action.payload;
        state.isSaving = false;
      })
      .addCase(saveAnswers.pending, (state) => {
        state.error = null;
        state.isSaving = true;
      })
      .addCase(saveAnswers.rejected, (state, action) => {
        state.error = action.error.name;
        state.isSaving = false;
      });
  },
});

export const selectIsSaving = (state: RootState): boolean =>
  state.answer.isSaving;
