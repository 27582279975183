import { ApplicationContext } from '@dimatech/shared/lib/application';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { Heading1, Heading2 } from 'components/Heading';
import { Info } from 'components/Info';
import { Modal } from 'components/Modal';
import { ServiceError } from 'components/ServiceError';
import { config } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SurveyStatus } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from './Footer';
import { Page } from './Page';
import {
  getSurvey,
  selectPage,
  selectSurvey,
  selectSurveyError,
} from './api/surveySlice';

export const Survey = (): JSX.Element | null => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const application = useContext(ApplicationContext);
  const page = useAppSelector(selectPage);
  const survey = useAppSelector(selectSurvey);
  const error = useAppSelector(selectSurveyError);
  const dispatch = useAppDispatch();
  const {
    token = '',
    surveyId = '',
    entityId = '',
    customerId = '',
  } = useParams();

  const [messageAnyProblem, setMessageAnyProblem] = useState(false);

  const heading =
    survey?.form?.descriptionKey &&
    i18n.exists(survey?.form?.descriptionKey) &&
    t(survey?.form?.descriptionKey).trim();

  useEffect(() => {
    if ((!surveyId || surveyId.length < 20) && (!token || token.length < 20)) {
      navigate('/incorrecturl');
      return;
    }

    dispatch(getSurvey({ token, surveyId, customerId, entityId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, surveyId]);

  useEffect(() => {
    if (survey || error) {
      document.querySelector('.loader-container')?.remove();
    }

    if (application) {
      const applicationTitle = t(`Account.Name.${config.currentSurvey}`);

      if (survey?.form?.displayNameKey) {
        document.title = `${applicationTitle} - ${t(
          survey.form.displayNameKey
        )}`;
      } else {
        document.title = applicationTitle;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, survey, application]);

  const now = new Date();
  const closed =
    ((survey?.validTo && now > new Date(survey?.validTo)) ||
      (survey?.validFrom && now < new Date(survey?.validFrom))) ??
    true;

  return (
    <>
      {error && <ServiceError error={error} component="Survey" />}
      {!error &&
        survey &&
        (() => {
          switch (survey.status) {
            case SurveyStatus.Finished:
              return closed && token ? (
                <Info titleId="Survey.ClosedTitle" textId="Survey.ClosedText" />
              ) : (
                <Info
                  titleId="Survey.FinishedTitle"
                  textId="Survey.FinishedText"
                />
              );
            default:
              return closed && token ? (
                survey?.validFrom ? (
                  <Info
                    titleId="Survey.ClosedTitle"
                    textId="Survey.ClosedText"
                  />
                ) : (
                  <Info
                    titleId="Survey.NotScheduledTitle"
                    textId="Survey.NotScheduledText"
                  />
                )
              ) : (
                survey.form && page && (
                  <Main>
                    {page.isFirst && survey.form.displayNameKey && (
                      <Heading1>{t(survey.form.displayNameKey)}</Heading1>
                    )}

                    <Modal
                      id="surveyAnyProblem"
                      visible={messageAnyProblem}
                      setVisible={setMessageAnyProblem}
                    >
                      {t('Survey.Message.AnyProblem')}
                    </Modal>

                    {!!heading && !page?.isFirst && (
                      <Heading2>{heading}</Heading2>
                    )}

                    <Page />
                    <Footer />
                  </Main>
                )
              );
          }
        })()}
    </>
  );
};

Survey.displayName = 'Survey';

const Main = styled.div`
  padding: 32px;
  min-height: calc(100vh - 135px);
  padding-bottom: 150px;
  background: ${({ theme }: { theme: Theme }) => theme.colors.surface};

  @media screen and (max-width: ${Breakpoints.medium}) {
    padding-bottom: 169px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding: 16px 8px 140px;
  }
`;
