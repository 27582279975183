import { Theme } from '@dimatech/shared/lib/themes';
import { css } from 'styled-components';

export const datepicker = css`
  .react-datepicker {
    margin-top: 4px;
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
    z-index: 200;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    font-family: none;
    font: ${({ theme }: { theme: Theme }) => theme.fonts.base};
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
  .react-datepicker__navigation:focus-visible {
    outline: none;
  }

  .react-datepicker__navigation:focus-visible &:before {
    outline: none;
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};
  }

  .react-datepicker__header {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.background};
    padding: 6px 0;
    font-weight: normal;
    border-bottom: 1px solid
      ${({ theme }: { theme: Theme }) => theme.colors.border};
  }

  .react-datepicker__year--container {
    width: 200px;
  }

  .react-datepicker__year {
    margin: 14px;
  }

  .react-datepicker__year .react-datepicker__year-text {
    width: calc(33.3333% - 4px);
    padding: 6px;
  }

  .react-datepicker__year-text:focus-visible {
    outline: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0;
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0;
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.secondary};
  }
`;
