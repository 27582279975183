import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import '@dimatech/shared/lib/polyfills';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { config } from 'config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { initializeAxiosResponseInterceptor } from 'middleware/axiosResponseInterceptor';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from 'store';
import App from './App';
import './localization';
import reportWebVitals from './reportWebVitals';

(async () => {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.key,
    options: {
      bootstrap: 'localStorage',
      diagnosticOptOut: true,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  initializeAxiosResponseInterceptor();

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <LaunchDarklyProvider>
      <Provider store={store}>
        <Router>
          <LocalizationProvider>
            <TrackingProvider>
              <App />
            </TrackingProvider>
          </LocalizationProvider>
        </Router>
      </Provider>
    </LaunchDarklyProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
