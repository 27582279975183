import { Theme } from '@dimatech/shared/lib/themes';
import BackgroundInputChecked from 'assets/images/checkbox-bg-marked.png';
import BackgroundInput from 'assets/images/checkbox-bg.png';
import {
  selectCurrentPageIndex,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { Input, Label } from 'features/survey/question/OptionElements';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Question, QuestionValue } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Checkbox = ({
  option,
  question,
}: {
  option: QuestionValue;
  question: Question;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const pageIndex = useAppSelector(selectCurrentPageIndex);
  const { t } = useTranslation();
  const key = `${question.id}_${option.value}`;
  const answer = question.questionType.answer;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const array = [...(answer?.array ?? [])];
    const value = +option.value;

    if (e.currentTarget.checked && !array.includes(value)) {
      array.push(value);
    }

    if (!e.currentTarget.checked && array.includes(value)) {
      array.splice(array.indexOf(value), 1);
    }

    dispatch(
      surveyActions.answer({
        questionId: question.id,
        pageIndex,
        answer: { array },
      })
    );
  };

  return (
    question && (
      <OptionItem key={key}>
        <OptionInput
          type="checkbox"
          onChange={(e) => handleChange(e)}
          key={key}
          id={key}
          name={question.id.toString()}
          value={option.value}
          defaultChecked={answer?.array?.includes(+option.value)}
        />
        <OptionLabel htmlFor={key}>{t(`${option.optionKey}`)}</OptionLabel>
      </OptionItem>
    )
  );
};

Checkbox.displayName = 'Checkbox';

const OptionItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const OptionInput = styled(Input)`
  &:checked + label {
    &:before {
      content: ' ';
      background: url(${BackgroundInputChecked}) 0 0 / cover no-repeat
        ${({ theme }: { theme: Theme }) => theme.colors.surface};
    }
  }
`;

const OptionLabel = styled(Label)`
  &::before {
    background: url(${BackgroundInput}) 0 0 / cover no-repeat;
    border-radius: 2px;
    margin: 0 8px 0;
  }
`;
