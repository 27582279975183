import { Theme } from '@dimatech/shared/lib/themes';
import { listToTree } from '@dimatech/shared/lib/utils';
import { HelpText } from 'components/HelpText';
import {
  selectEntityId,
  selectHasValidated,
  selectSurvey,
  surveyActions,
} from 'features/survey/api/surveySlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Entity, SurveyValidationError } from 'models';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { QuestionItem, QuestionText } from './QuestionElements';
import { ValidationMessage } from './ValidationMessage';

export const EntityTree = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const survey = useAppSelector(selectSurvey);
  const entityId = useAppSelector(selectEntityId);
  const hasValidated = useAppSelector(selectHasValidated);
  const { t } = useTranslation();

  const [tree, setTree] = useState<Entity[] | undefined>();

  const toOptions = (entities: Entity[], depth = 0): JSX.Element[] => {
    const options = entities.map((entity: Entity) => (
      <Fragment key={entity.id}>
        <option value={entity.id}>
          {'\u00A0'.repeat(depth * 2)}
          {depth > 0 ? '- ' : ''}
          {entity.name}
        </option>

        {toOptions(entity.children, depth + 1)}
      </Fragment>
    ));

    return options;
  };

  const handleChange = (id: string) => {
    dispatch(surveyActions.updateEntityId(id));
  };

  useEffect(() => {
    if (survey?.form.entities) {
      const orphans = survey.form.entities
        .filter(
          (entity) =>
            !survey.form.entities?.some((child) => entity.parentId === child.id)
        )
        .map((orphan) => orphan.parentId);

      setTree(listToTree(survey.form.entities, orphans));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey?.form.entities]);

  const hasError = hasValidated && !entityId;

  return (
    <QuestionItem key="entity" className={hasError ? 'invalid' : ''}>
      <QuestionText>{t('Survey.SelectEntity.Question')}</QuestionText>

      <Select
        value={entityId}
        onChange={(e) => handleChange(e.currentTarget.value)}
      >
        <option value="">{t('Survey.DropdownPlaceholder')}</option>

        {tree && toOptions(tree)}
      </Select>

      <HelpText helpText={t('Survey.SelectEntity.Help')} uniqueId={-1} />
      {hasError && (
        <ValidationMessage validation={[SurveyValidationError.Required]} />
      )}
    </QuestionItem>
  );
};

EntityTree.displayName = 'EntityTree';

const Select = styled.select`
  width: 240px;
  height: 34px;
  border-radius: 5px;

  margin-top: 24px;
  padding: 6px 8px;

  &:focus-visible {
    outline: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;
