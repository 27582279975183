import { config } from '@dimatech/shared/lib/config';
import { extractTextFromHTML } from '@dimatech/shared/lib/utils';
import {
  Form,
  Page,
  Question,
  QuestionDisplaySubType,
  QuestionDisplayType,
  SurveyValidationError,
} from 'models';

export const validateForm = (form: Form): boolean =>
  form.pages.every((page: Page) => validatePage(page));

export const validatePage = (page: Page): boolean =>
  page.questions.every(
    (question: Question) => validateQuestion(question) === null
  );

export const getInvalidQuestions = (page: Page): (number | null)[] =>
  page.questions
    .map((question: Question) => {
      if (validateQuestion(question) != null) return question.id;
      else return null;
    })
    .filter((questionId) => questionId != null);

export const validateQuestion = (
  question: Question
): SurveyValidationError[] | null => {
  switch (question.questionType.type) {
    case QuestionDisplayType.Option:
      return validateQuestionOption(question);

    case QuestionDisplayType.FormattedText:
      return validateQuestionFormattedText(question);

    case QuestionDisplayType.Number:
      return validateQuestionNumber(question);

    case QuestionDisplayType.Date:
      return validateQuestionDate(question);
  }
};

export const validateQuestionOption = (
  question: Question
): SurveyValidationError[] | null => {
  if (!question.questionType.isMandatory) {
    return null;
  }

  const answer = question.questionType.answer;

  switch (question.questionType.subType) {
    case QuestionDisplaySubType.Checkbox:
      return answer?.array && answer.array.length > 0
        ? null
        : [SurveyValidationError.Required];

    case QuestionDisplaySubType.VerticalOption:
    case QuestionDisplaySubType.HorizontalOption:
    default:
      return answer?.number ? null : [SurveyValidationError.Required];
  }
};

export const validateQuestionFormattedText = (
  question: Question
): SurveyValidationError[] | null => {
  const answer = question.questionType.answer;
  const text = extractTextFromHTML(answer?.string ?? '');
  const limitInBytes = config.ui.textEditor.maxBytes;
  const textBytes = new TextEncoder().encode(text);

  if (!question.questionType.isMandatory) {
    if (answer?.string && textBytes.length > limitInBytes) {
      return [SurveyValidationError.MaxCharacters];
    } else {
      return null;
    }
  }

  return answer?.string &&
    answer.string.length > 0 &&
    textBytes.length <= limitInBytes
    ? null
    : answer?.string && textBytes.length > limitInBytes
    ? [SurveyValidationError.MaxCharacters]
    : [SurveyValidationError.Required];
};

export const validateQuestionNumber = (
  question: Question
): SurveyValidationError[] | null => {
  if (!question.questionType.isMandatory) {
    return null;
  }

  const answer = question.questionType.answer;

  return answer?.number ? null : [SurveyValidationError.Required];
};

export const validateQuestionDate = (
  question: Question
): SurveyValidationError[] | null => {
  const answer = question.questionType.answer;

  if (question.questionType.isMandatory && !answer?.number) {
    return [SurveyValidationError.Required];
  }

  switch (question.questionType.subType) {
    case QuestionDisplaySubType.YearPast:
      return answer?.number && answer.number > new Date().getFullYear()
        ? [SurveyValidationError.YearOnly]
        : null;
    case QuestionDisplaySubType.YearFuture:
      return answer?.number && answer.number < new Date().getFullYear()
        ? [SurveyValidationError.YearOnly]
        : null;
  }

  return null;
};

export const validateQuestionDropdown = (
  question: Question
): SurveyValidationError[] | null => {
  if (!question.questionType.isMandatory) {
    return null;
  }

  const answer = question.questionType.answer;

  return answer?.number ? null : [SurveyValidationError.Required];
};
